import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDiscord,
    faTwitter,
    faYoutube,
    faFacebook,
    faInstagram,
    faDribbbleSquare,
    faAdversal,
    faCcDiscover,
} from "@fortawesome/free-brands-svg-icons"; 

export default function SocialFollow() {
    return (
      <div className="social-container">
        <a href="https://twitter.com/PixelPugs_NFT" className="twitter social">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a href="https://discord.gg/5ZG59TFFxZ" className="discord social">
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
      </div>
      
    );
  }