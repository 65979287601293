import React, { useEffect, useState, useRef, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import FAQ from './FAQ';
import './index.css'
import SocialFollow from "./SocialFollow";
import "aos/dist/aos.css";
import Aos from "aos";

const { ethers } = require("ethers");

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
  
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonPerky = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondarypug);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButtonPerky = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primarypugs);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 150px;
  @media (min-width: 767px) {
    width: 250px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary-none);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  text-shadow:
  -1px -1px 0 #000,  
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000;
`;

function App() {
  const [faqs, setfaqs] = useState([
    {
      question: 'What are Pixel Pugs?',
      answer: 'Pixel Pugs is a collection of 10,000 randomly generated NFTs living on the Polygon blockchain.',
      open: false
    },
    {
      question: 'How do I buy one?',
      answer: 'Connect your MetaMask wallet, select how much you want to buy, and press the "MINT" button! After you mint, you can see your Pixel Pug on OpenSea!',
      open: false
    },
    {
      question: 'What is the price of one Pixel Pug?',
      answer: '3 MATIC! That is $5 - $7.',
      open: false
    },
    {
      question: 'How much Pixel Pugs can I own?',
      answer: 'There is no limit, but you can only mint 25 Pixel Pugs per transaction.',
      open: false
    },
    {
      question: 'When does minting start?',
      answer: 'November 13, 2021.',
      open: false
    },
  ]);

  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      }

      return faq;
    }))
  }

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [mintAmount, setMintAmount] = useState(1);
  const [feedback, setFeedback] = useState("Your Pixel Pug is waiting for you!");
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimingToken, setClaimingToken] = useState(false);
  const [claimingTokenP, setClaimingTokenP] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    CONTRACT_ADDRESS_CLAIM: "",
    CONTRACT_ADDRESS_PERKY_CLAIM: "",
    SCAN_LINK: "",
    SCAN_LINK_PUGS: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_PERK: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("There was an error. Please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations! You successfully minted a ${CONFIG.NFT_NAME}. Go to opensea.io to view it!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimTokens = () => {
    let totalCostWei = String(0);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Claiming your $PGC...`);
    setClaimingToken(true);
    blockchain.smartContract2.methods
      .claimToken("0x9c62b5079e994165d8bc7a12b46be079047da5c7")
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS_CLAIM,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("There was an error. Please try again later.");
        setClaimingToken(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations! You successfully claimed your $PGC.`
        );
        setClaimingToken(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 25) {
      newMintAmount = 25;
    }
    setMintAmount(newMintAmount);
  };
  

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date('December 20, 2021 20:00:00 GMT+0800').getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        //stop timer
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }

    }, 1000);
  }

  useEffect(() => {
    startTimer();
    return () => {
        clearInterval(interval.current);
    };
  });

  return (
    <s.Screen>      <s.Container
    flex={1}
    ai={"center"}
    style={{ padding: 24, backgroundColor: "var(--primary)" }}
    image={CONFIG.SHOW_BACKGROUND ? "/config/images/pixelpug.png" : null}
  >
    <div id="mint"></div>
    <SocialFollow></SocialFollow>
    <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
    <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
    <s.SpacerXLarge />
      <s.Container flex={1} jc={"center"} ai={"center"}>
        <StyledImg alt={"example"} src={"/config/images/hpix2.gif"} />
      </s.Container>
      <s.SpacerMegaLarge />
      <s.Container
              image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
        flex={2}
        jc={"right"}
        ai={"right"}
        style={{
          backgroundColor: "var(--accent)",
          padding: 50,
          borderRadius: 20,
          border: "7px solid var(--secondary)",
          boxShadow: "",
        }}
      >
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 80,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
          
        >
          {data.totalSupply} / {CONFIG.MAX_SUPPLY}
        </s.TextTitle>
        <s.SpacerSmall/>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--primary-text)",
          }}
        >
        </s.TextDescription>
        <s.SpacerXMegaSmall />
        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
          <>
            <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              SOLD OUT
            </s.TextTitle>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              You can still find {CONFIG.NFT_NAME} on
            </s.TextDescription>
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK} className="opensealink">
              {CONFIG.MARKETPLACE}
            </StyledLink>
          </>
        ) : (
          <>
            <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 26}}
            >
              1 Pixel Pug costs {CONFIG.DISPLAY_COST}{" "}
              {CONFIG.NETWORK.SYMBOL}.
            </s.TextTitle>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 19}}
            >
              Excluding gas fees.
            </s.TextDescription>
            <s.SpacerXSmall />
            {blockchain.account === "" ||
            blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontSize: 25,
                  }}
                >
                  Connect to the {CONFIG.NETWORK.NAME} network
                </s.TextDescription>
                <s.SpacerXSmall />
                <StyledButton
                onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                }}
                >
                 CONNECT
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  
                  </>
                ) : null}
              </s.Container>
            ) : (
              
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                
                </s.Container>
                <s.SpacerSmall />
                <s.SpacerXSmall></s.SpacerXSmall>
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "MINTING" : "MINT"}
                  </StyledButton>
                  <s.SpacerXSmall></s.SpacerXSmall>
                  <StyledButton
                    disabled={claimingToken ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimTokens();
                      getData();
                    }}
                    style={{
                      fontSize: 10.6,
                    }}
                  >
                    {claimingToken ? "CLAIMING" : "CLAIM $PGC"}
                  </StyledButton>
                </s.Container>
              </>
            )}
          </>
        )}
      </s.Container>
      <s.SpacerLarge />
    </ResponsiveWrapper>
    <s.SpacerMedium />
    <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
      <s.OutlinedTextDescription
        style={{
          textAlign: "center",
          color: "var(--primary-text)",

        }}
      >
        Please make sure you are connected to the right network (
        {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Once you make the purchase, you cannot undo this action.
      </s.OutlinedTextDescription>
      <s.SpacerSmall />
      <s.OutlinedTextDescription
        style={{
          textAlign: "center",
          color: "var(--primary-text)",
        }}
      >
        The gas limit has been set to {CONFIG.GAS_LIMIT} so the contract can successfully mint your NFT. We recommend you don't change this or the transaction might fail.
      </s.OutlinedTextDescription>
      
      <s.SpacerSmall/>
      <StyledLink target={"_blank"} rel="noopener noreferrer" href={CONFIG.SCAN_LINK} div id="contract">
            {truncate(CONFIG.CONTRACT_ADDRESS,15)}
          </StyledLink>
    </s.Container>
  </s.Container>
  <s.Container
    flex={1}
    ai={"center"}
    style={{ padding: 24, backgroundColor: "var(--primary)" }}
    image={CONFIG.SHOW_BACKGROUND ? "/config/images/perkypugs1.png" : null}
  >
    <div id="mint"></div>
    <SocialFollow></SocialFollow>
    <StyledLogo alt={"logo"} src={"/config/images/perkpug.png"} />
    <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
    <s.SpacerXLarge />
      <s.Container flex={1} jc={"center"} ai={"center"}>
        <StyledImg alt={"example"} src={"/config/images/perkypugs.gif"} />
      </s.Container>
      <s.SpacerMegaLarge />
      <s.Container
              image={CONFIG.SHOW_BACKGROUND ? "/config/images/perkbg.png" : null}
        flex={2}
        jc={"right"}
        ai={"right"}
        style={{
          backgroundColor: "var(--primarypugs)",
          padding: 50,
          borderRadius: 20,
          border: "7px solid var(--secondarypug)",
          boxShadow: "",
        }}
      >
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 80,
          
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
          
        >
          1000 / {CONFIG.MAX_SUPPLY_PUGS}
        </s.TextTitle>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--primary-text)",
          }}
        >
        </s.TextDescription>
        <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)",
            fontSize: 50}}
            >
              SOLD OUT
            </s.TextDescription>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)", fontSize:25 }}
            >
              You can still find Perky Pugs on
            </s.TextDescription>
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_PERK} className="opensealink"
                          style={{
                          fontSize: 25}}>
              OpenSea
            </StyledLink>
      </s.Container>
      <s.SpacerLarge />
    </ResponsiveWrapper>
    <s.SpacerMedium />
    <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
    </s.Container>
  </s.Container>
  <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bge.png" : null}
      >
        <div id="faq"></div>
        <StyledLogo alt={"logo"} src={"/config/images/faq6.png"}/>
        <s.SpacerXSmall/>
        <div className="App">
      <div className="faqs">
        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
      </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/rm321.png" : null}
      >
        <div id="rm"></div>
        <StyledLogo alt={"logo"} src={"/config/images/rm3.png"}/>
        <s.SpacerMedium></s.SpacerMedium>
        <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/tomoon.png" : null}
        style={{
          padding: 50,
          borderRadius: 20,
          boxShadow: "",
          width: 1200,
          backgroundColor: "var(--primary)"
        }}
      >
                <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 75,
              marginTop: -10,
            }}
          >
            Info
          </s.OutlinedTextDescription>
        <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 35,
              paddingRight: 720,
            }}
          >
            First off; what are the Pixel Pugs?!
          </s.OutlinedTextDescription>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 20,
              paddingLeft: 15,
              paddingRight: 500,
            }}
          >
            The Pixel Pugs are a group of 10,000 randomly created NFTs living on the Polygon blockchain. With over 30+ traits, every single Pixel Pug is unique and none look exactly the same.
          </s.OutlinedTextDescription>
          <s.SpacerMedium></s.SpacerMedium>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 35,
              paddingRight: 523,
            }}
          >
            How did they get here and what are their intentions?
          </s.OutlinedTextDescription>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 20,
              paddingLeft: 15,
              paddingRight: 500,
            }}
          >
            The world's best scientists are currently researching the situation, but rumors say the Pixel Pugs arrived from a parallel universe, which uses 2D physics and pixels.
          </s.OutlinedTextDescription>
          <s.SpacerMedium></s.SpacerMedium>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 35,
              paddingRight: 596,
            }}
          >
            What are 'Mutants', 'Radioactives', or 'Aliens'?!
          </s.OutlinedTextDescription>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 20,
              paddingLeft: 15,
              paddingRight: 500,
            }}
          >
            When the Pixel Pugs transferred universes, they caused a huge radiation influx which mutated a lot of the Pixel Pugs. The lucky few who were able to be mutated are now more rarer than the others.
          </s.OutlinedTextDescription>
          </s.Container>
<s.SpacerMedium></s.SpacerMedium>
          <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/prt1.png" : null}
        style={{
          padding: 50,
          borderRadius: 20,
          boxShadow: "",
          width: 1200,
          backgroundColor: "var(--primary)"
        }}
      >
                <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 75,
              marginTop: -10,
            }}
          >
            Launch
          </s.OutlinedTextDescription>
        <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 35,
              paddingRight: 950,
            }}
          >
            Initial launch
          </s.OutlinedTextDescription>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 20,
              paddingLeft: 15,
              paddingRight: 500,
            }}
          >
            On November 13, 2021, the Pixel Pugs officially arrived in our universe. The website went live at 8 AM EST.
          </s.OutlinedTextDescription>
          <s.SpacerMedium></s.SpacerMedium>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 35,
              paddingRight: 840,
            }}
          >
            Giveaways and contests
          </s.OutlinedTextDescription>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 20,
              paddingLeft: 15,
              paddingRight: 500,
            }}
          >
            Over the course of a few weeks, we will be holding a lot of giveaways and contests. These are held to give an opportunity to those who cannot mint.
          </s.OutlinedTextDescription>
          <s.SpacerMedium></s.SpacerMedium>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 35,
              paddingRight: 596,
            }}
          >
            Crypto currency and game; plans for the future
          </s.OutlinedTextDescription>
          <s.OutlinedTextDescription
            style={{
              color: "var(--primary-text)",
              fontSize: 20,
              paddingLeft: 15,
              paddingRight: 500,
            }}
          >
            This part of the future will be left blank for now; full plans haven't been revealed yet. Nothing is final, and anything can change depending on the situation.
          </s.OutlinedTextDescription>
          </s.Container>
          </s.Container>
    </s.Screen>
  );
}

export default App;
